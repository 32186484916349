/* Font */
@import url('https://fonts.googleapis.com/css?family=Comic+Sans+MS|Raleway:400,600');

/* Design */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  background-color: #ecf9ff;
}

body {
  width: 100vw;
  margin: 0;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Changed to Comic Sans */
  color: #272727;
}

h1 {
  margin: 0;
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Keep Raleway for headings if preferred */
  font-weight: 300;
  font-size: 3.5rem;
  color: white;
  background-color: #365369;
  transition: all 0.4s ease;
  text-align: center;
  padding: 10px;
}

h1 span {
  display: block;
  font-size: 1em;
  line-height: 1.3;
  font-weight: 300;
}

.two {
  margin-top: 20px;
}

.two h1 {
  text-transform: capitalize;
  padding: 30px;
}



.page-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
}

/* Card container with grid layout */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Responsive grid */
  gap: 20px;
  width: 100%;
  max-width: 90%;
  margin: 20px auto; /* Center container horizontally */
}

/* Card styling */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  background-color: #fff;
  width: 100%;
  height: 230px; /* Adjusted for uniform card size */
}

.card:hover {
  transform: scale(1.05);
}

/* Character image */
.character {
  position: absolute;
  left: 50%;
  height: 250px;
  width: auto;
  transform: scale(0.6);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 2;
  opacity: 0;
  top: 90%;
  pointer-events: none;
}

.card:hover .character {
  opacity: 1;
  transform: translate(-50%, -100%) scale(0.6); /* Ensure character overlaps the card */
}

/* Card cover image */
.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fits within the container without cropping */
  object-position: center; /* Centers the image */
  z-index: 1;
  transition: filter 0.3s ease;
}

.title {
  margin: 0; /* Remove default margin */
  padding: 10px; /* Remove default padding */
  font-family: 'Comic Sans MS', cursive, sans-serif;
  padding-bottom: 25px;
}

.card:hover .cover-image {
  filter: brightness(0.5); /* Reduce brightness on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjusted grid for smaller screens */
  }

  .title {
    font-size: 0.875rem; /* Adjust title font size */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Single column for smaller screens */
  }

  .card {
    width: 100%; /* Full width for mobile */
    height: 180px; /* Adjusted for mobile */
  }

  .title {
    font-size: 0.75rem; /* Smaller title font size */
  }
}
.focus-title {
  font-size: 1.2rem; /* Adjust font size */
  font-weight: bold; /* Adjust font weight */
  text-align: center; /* Center the text */
  line-height: 1.4; /* Line height for better spacing */
  color: black;
}

/*................ Pricing................. */

.pricing-section {
  text-align: center;
  margin-bottom: 50px;
  

}

.pricing-heading {
  font-size: 4rem;
  color: #34546c; /* Apply deep blue to headings */
  margin-bottom: 30px;
  font-weight: bold;
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Changed to Comic Sans */

}
/* Pricing Card Title */
.pricing-card h3 {
  font-size: 1.5rem;
  color: #34546c; /* Apply deep blue to card titles */
  margin-bottom: 20px;
}

/* Toggle Switch for Monthly/Yearly Pricing */
.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.toggle-container span {
  font-size: 5rem;
  margin: 0 10px;
  font-weight: 700;
  color: black;
}

.switch {
  position: relative;
  display: inline-block;
  width: 82px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2d87ff;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.pricing-container {
  display: flex;
  justify-content: space-around;
  padding: 50px;
  background-color: #ecf9ff;
}

.pricing-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.pricing-card:hover {
  transform: translateY(-10px);
}



.pricing-card .price {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2d87ff;
  margin: 20px 0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
}

.pricing-card ul li {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #666;
}

.pricing-card button {
  padding: 10px 20px;
  background-color: #71e4d4; /* Deep blue background */
  color: #34546c; /* White text for contrast */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pricing-card button:hover {
  background-color: #34546c;
  color: #71e4d4; /* Soft turquoise on hover */
}

/* Toggle Text */
.toggle-container span {
  font-size: 1.25rem; 
  margin: 0 15px;
  color: #34546c; /* Apply deep blue to toggle labels */
}


.highlighted {
  background-color: #ffffff;
  border: 2px solid #2d87ff;

  transform: scale(1.05);
}

/* Highlight the selected card */
.selected {
  border: 2px solid #2d87ff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/*................ Benefit................. */

.b-how-can-we-help {
  background-color: #ecf9ff;
  padding: 50px 0;
  text-align: center;
}

.b-heading {
  font-size: 4rem;
  color: #34546c; /* Apply deep blue to headings */
  margin-bottom: 30px;
  font-weight: bold;
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Changed to Comic Sans */
}

.b-highlight {
  color: #3498db; /* Blue color for 'we' */
}

.b-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 20px;
}

.b-card {
  width: 200px;
  text-align: center;
  margin: 20px;
  padding: 25px;
  border: 0.5px solid transparent; /* Initial border, changeable on hover */
  border-radius: 10px; /* Smooth corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, border-color 0.3s ease; /* Animation for transform and border color */
}

.b-card:hover {
  transform: translateY(-5px); /* Slight upward movement on hover */
  border-color: #2d87ff; /* Change border color on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional shadow for depth effect */
}

.b-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.b-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.b-card-description {
  color: #7a7a7a;
  font-size: 1rem;
}

.b-cta-button {
  margin-top: 30px;
}

.b-cta {
  background-color: #34d399;
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.b-cta:hover {
  background-color: #10b981;
}

/* Responsive Design */
@media (max-width: 768px) {
  .b-container {
    flex-direction: column;
    align-items: center;
  }

  .b-card {
    margin-bottom: 40px;
  }
}
