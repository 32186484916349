/* Basic Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Body Styling */
body {
    font-family: Arial, sans-serif;
    background-color: #ecf9ff;
    color: #333;
    padding: 20px;
}
.feature-lists-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping of cards if needed */
    justify-content: center; /* Center cards horizontally within the container */
    gap: 10px;
    column-gap: 20PX; /* Space between cards */
    max-width:1500px; /* Optional: Limit max width of the container */
    margin-bottom: 50px; /* Space below the container */
  }
.bold-heading{
    font-weight: bold;
}
/* Overlay Styling */
/* Feature List Container */
.feature-list-container {
    background: #f5f5f5;
    border-radius: 0px;
    padding-right: 0;
    max-width: 350px; /* Adjusted to fit the cards */
    width: 80%;
    border: 0.2px  solid #D3D3D3;
    position: relative;
    overflow-y: auto; /* Add scroll if content overflows */
    margin: 0;
}


/* Heading Styling */
.feature-list-container h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
}

/* Feature Columns Styling */
.feature-columns {
    width: 100%;
    display: grid;
    padding: 10px;
    padding-top: 0;
    grid-template-columns: repeat(2, 1fr); /* Create 3 equal-width columns */
    gap: 10px;/* Space between cards */
    justify-items: center; /* Center the cards horizontally */
}

/* Each Column */
.feature-column {
    width: 100%; /* 3 items per row */
    display: flex;
    flex-direction: column;
}


/* Feature Item Styling */
.feature-item {
    background-color: #fff; /* Card background color */
    padding: 0; /* Remove extra padding as it's added to the label */
    border-radius: 0;
    text-align: center;
    display: flex;
    border: 0.2px solid #b4b3b3; 
    margin: 0;
    align-items: center;
    margin-right: -5px;
    flex-direction: column;
    height: 50px; /* Adjusted height to fit cards */
    overflow: hidden; /* Ensure content doesn't overflow */
    position: relative; /* Required for positioning checkbox */
    transition: border 0.ms, box-shadow 0.3s; /* Smooth transition for border and shadow */
    /*border: 2px solid transparent;  Default border to be invisible */
    width: 100%; /* Ensure the card takes the full width of the column */
}
.feature-item.selected {
    border: 2px solid #3498db; /* Highlight border color */
  }
  .feature-item:hover {
    border-color: #3498db; /* Optional hover effect */
  }
  
  .feature-description {
    font-size: 14px; /* Adjust size as needed */
    color: #555; /* Adjust color as needed */
    margin-top: 0; /* Add space between label and description */
    text-align: left;
  }


/* Feature Row Styling */
.feature-row {
    display: flex;
    flex: 1;
    gap: 10px; /* Space between the sections */
    margin: 0;
    padding: 0;
}
@media (max-width: 768px) {
    .feature-columns {
        grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns on smaller screens */
    }
}

@media (max-width: 480px) {
    .feature-columns {
        grid-template-columns: 1fr; /* Adjust to 1 column on very small screens */
    }
}
.feature-checkbox {
    display: inline; /* Ensure checkbox is visible */
    margin: 0; /* Remove default margin */
    position: absolute; /* Position checkbox */
    top: 20px; /* Adjust position */
    left: 5px; /* Adjust position */
    transform: scale(0.8); /* Adjust size */
}

.feature-icon {
    width: 40px; /* Reduced icon size */
    height: 40px; /* Reduced icon size */
    margin-left: 3px;
}

.feature-item h2 {
    font-size: 0.7rem; /* Reduced font size */
    margin-top: 2px ; /* Adjusted margin */
    width: 100%;
}

.feature-item p {
    font-size: 0.7rem; /* Reduced font size */
    color: #777;
}

/* Button Styling */
.close-overlay, .download-pdf {
    background: #f44336;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 1rem;
    border-radius: 4px;
    width: 200px;
    text-align: center;
    margin-bottom: 15px;
}

.download-pdf {
    background: #4CAF50;
}
.label{
    padding: 0;
}
/* Responsive design adjustments */
@media (max-width: 768px) {
    .feature-columns {
        flex-direction: column; /* Stack columns vertically on smaller screens */
    }
}
.feature-label h2{
    margin-top: 5px;
}
/* Styling for Label */
.feature-item label {
    display: flex;
    /*justify-content: center;  Center content inside the card */
    gap: 0px;
    width: 100%;
    height: 100%; /* Make label cover the entire height of the card */
    cursor: pointer; /* Pointer cursor to indicate clickable area */
    padding: 0px; /* Add padding to label */
    box-sizing: border-box; /* Ensure padding and border are included in element's total width and height */
    border-radius: 8px; /* Match card border radius */
    background-color: #fff; /* Ensure label background matches card background */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for background color and shadow */
}

.label-container {
    text-align: left;
    margin-top: 15px;
    display: flex;
    justify-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap:3px
    
}
/* Highlight card border when the checkbox is checked */
.feature-checkbox:checked + label {
    border: 2px solid #00796b; /* Highlight border color */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Optional: add shadow to indicate selection */
    border-radius: 4px;
}
h2.label {
    margin: 0; /* Remove any default margin */
    padding: 0; /* Ensure no padding is added */
     /* Adjust font size if needed */
     font-size: 8px;
}

/* Ensure the feature icon and text color are not affected */
.feature-checkbox:checked + label h2 {
    color: #333; /* Keep text color the same or adjust if needed */
}
